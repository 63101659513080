
import React from 'react';
import Button from 'react-bootstrap/Button';
import { FiSend } from 'react-icons/fi';

const Newsletter = () => {

    return (
        <form className="py-3" action="https://roelheremans.us6.list-manage.com/subscribe/post?u=bf201e57ce0bb78128827cd0f&amp;id=88efac55e8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
            <div className="row">
                <div className="col-9">
                    <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" className="form-control" placeholder="Enter email address" required />
                </div>
                <div className="col-3">
                    <Button variant="outline-dark" type="submit" name="subscribe" id="mc-embedded-subscribe"><FiSend style={{ color: '#dfda00' }} /></Button>
                </div>
            </div>
        </form>
    );
}


export default Newsletter;

