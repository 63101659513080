import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { FaFacebookSquare, FaLinkedin, FaInstagramSquare } from "react-icons/fa";
import Markdown from "react-markdown";


const About = ({ data }) => {
    const [show, setShow] = useState(false);
    return (
        <div className="container">
            <div className="site-section mt-4" id="about">
                <div className="d-flex justify-content-center">
                    <h2 className="heading-39291" style={{ color: '#548235' }}>About</h2>
                </div>
                <div className="line wow fadeIn" data-wow-delay="0.2s" />
                <div className="row">
                    <div className="col-md-5 mb-5 aboutContainer about">
                        <img src={data.img_src.publicURL} className="aboutImage" alt="Roel Heremans" />
                        <div className="overlayText overlayTextAbout">
                            <div className="d-inline-flex p-3">
                                <a href="https://www.facebook.com/RoelHeremans" target="_blank" rel="noopener noreferrer" className="smoothscroll p-2"><span className="aboutIcons"><FaFacebookSquare style={{ color: '#548235' }} /></span></a>
                                <a href="https://www.instagram.com/roelheremans/" target="_blank" rel="noopener noreferrer" className="p-2"><span className="aboutIcons"><FaInstagramSquare style={{ color: '#548235' }} /></span></a>
                                <a href="https://www.linkedin.com/in/roelheremans" target="_blank" rel="noopener noreferrer" className="p-2"><span className="aboutIcons"><FaLinkedin style={{ color: '#548235' }} /></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 pl-lg-5">
                        <Markdown source={data.section1} escapeHtml={false} className="text-justify" />
                        {
                            show ?
                                <div>
                                    <Markdown source={data.section2} className="text-justify" />
                                </div>
                                : null}

                        <div className="d-flex align-items-end flex-column">
                            <Button variant="outline-dark" onClick={() => { setShow(!show) }}>{show ? 'Read Less' : 'Read More'}</Button>
                        </div>
                    </div>
                </div>
            </div >
        </div>






        // <Container>
        //     <Row>
        //         <Col><h1>{data.title}</h1></Col>
        //     </Row>
        //     <Row>
        //         <Col md='6'>
        //             <Markdown source={data.section1} escapeHtml={false} />
        //             <Markdown source={data.section2} escapeHtml={false} />
        //         </Col>
        //         <Col md='6'>
        //             <img src={data.img_src.publicURL} height="300" width="400" alt="about me" />
        //         </Col>
        //     </Row>
        // </Container>
    )
}
export default About;