import React from "react";
import Parse from 'html-react-parser';
import { ImLocation2 } from "react-icons/im";
import { RiCalendarCheckFill } from "react-icons/ri";
import { BiLinkExternal } from "react-icons/bi";

const Prospect = ({ data }) => {
    var count = 0;

    function breakLine() {
        count = count + 1;
        if (count % 2 === 0) {
            return <div className="w-100 d-none d-sm-block d-md-none" />
        }
        else return null;
    }
    return (
        <div className="container">
            <div className="site-section" id="prospect">
                <div className="d-flex justify-content-center">
                    <h2 className="heading-39291" style={{ color: 'red' }}>Prospect</h2>
                </div>
                <div className="card-deck">
                    {data.Prospect.map((item, index) =>
                        <React.Fragment key={index}>
                            <div className="card mb-4">
                                <img className="card-img-top" src={item.img_src.publicURL} alt={item.title} />
                                <div className="card-body">
                                    <a href={item.url} target="_blank" rel="noopener noreferrer"><h5 className="card-title"><BiLinkExternal style={{ color: 'red' }} />&nbsp;{Parse(item.title)}</h5></a>
                                    <p className="card-text"><ImLocation2 />&nbsp;{Parse(item.location)}</p>
                                    <p className="card-text cardDate"><RiCalendarCheckFill />&nbsp;{item.date}</p>
                                </div>
                            </div>
                            {breakLine()}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Prospect;