import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Container } from "react-bootstrap";
import video from "../assets/images/heremans.mp4";
import video1 from "file-loader!../assets/images/heremans.ogg";
import video2 from "file-loader!../assets/images/heremans.mov";

const ReactCarousel = ({ data }) => {
    return (
        <Container fluid className="px-0">
            <div class="jumbotron jumbotron-fluid" style={{ top: '-4px' }}>
                <video id="video-background" autoPlay loop muted playsInline controls="true">
                    <source src={video} type="video/mp4" />
                    <source src={video1} type="video/ogg" />
                    <source src={video2} type="video/mov" />
                </video>
            </div>
            {/* <div className="d-block d-sm-block d-md-none">
                <Carousel style={{ top: '-70px' }}> autoPlay loop muted playsInline
                    {data.small.map((item, index) =>
                        <Carousel.Item key={index}>
                            <img className="slider slider--content" src={item.img_src.publicURL} alt={item.alt} />
                            <Carousel.Caption><p>{item.text}</p></Carousel.Caption>
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>
            <div className="d-none d-md-block d-lg-none">
                <Carousel style={{ top: '-70px' }}>
                    {data.medium.map((item, index) =>
                        <Carousel.Item key={index}>
                            <img className="slider slider--content" src={item.img_src.publicURL} alt={item.alt} />
                            <Carousel.Caption><p>{item.text}</p></Carousel.Caption>
                        </Carousel.Item>
                    )}
                </Carousel>
            </div>
            <div className="d-none d-lg-block d-xl-block">

                <Carousel style={{ top: '-70px' }}>
                    {data.large.map((item, index) =>
                        <Carousel.Item key={index}>
                            <img className="slider slider--content" src={item.img_src.publicURL} alt={item.alt} />
                            <Carousel.Caption><p>{item.text}</p></Carousel.Caption>
                        </Carousel.Item>
                    )}
                </Carousel>
            </div> */}
        </Container>
    )
}
export default ReactCarousel;