import React, { useState } from "react";
import Newsletter from "./newsletter";
//import cv from "../assets/docs/cv_2020.pdf";


const Contact = ({ data }) => {
    const [show, setShow] = useState(false);

    return (
        <div className="container">
            <div className="site-section mt-5 mb-5" id="contact">
                <div className="d-flex justify-content-center">
                    <h2 className="heading-39291" style={{ color: '#dfda00' }}>CONTACT</h2>
                </div>

                <div className="row justify-content-center">
                    <div className="col-sm-6 col-md-4 aboutContainer contact">
                        <img src={data.img_src.publicURL} className="aboutImage" alt="contact" />
                        <div className="overlayText">
                            <div className="d-inline-flex p-3">
                                <a href={data.mail} target="_top" rel="noopener noreferrer" className="smoothscroll p-2"><span style={{ color: '#dfda00' }}><b>{data.mail_text}</b></span></a>
                                <a href={data.roel_cv.publicURL} target="_blank" rel="noopener noreferrer" className="p-2"><span style={{ color: '#dfda00' }}><b>{data.cv_text}</b></span></a>
                                <a onClick={() => setShow(!show)} className="p-2"><span style={{ color: '#dfda00', cursor: 'pointer' }}><b>{data.newsletter_text}</b></span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        {show ?
                            <Newsletter />
                            : null}
                    </div>
                </div>
            </div >
        </div>
    )
}
export default Contact;