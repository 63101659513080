import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import About from "../components/about";
import Contact from "../components/contact";
import ReactCarousel from "../components/carousel";
import Prospect from "../components/prospect";
import Retrospects from "../components/retrospects";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Roel Heremans" keywords={[`Roelheremans`, `Sound Artist`, `audio`, `belgium`, `brussels`, `Vilvoorde`, `mental processes`, `Monolith # at Gare Maritime`, `Collaboration UNESCO Future Studies`, `European Archive of Voices`, `Training the Sense together with philosopher Louis Schreel`]} />
      <ReactCarousel data={data.strapiCarousel} />
      <Prospect data={data.strapiProspect} />
      <About data={data.strapiAbout} />
      <Retrospects data={data.allStrapiRetrospect.edges} />
      <Contact data={data.strapiContact} />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiAbout {
      title
      section1
      section2
      img_src {
        publicURL
      }
    }
    strapiCarousel {
      large {
        img_src {
          publicURL
        }
      }
      medium {
        img_src {
          publicURL
        }
      }
      small {
        img_src {
          publicURL
        }
      }
      video {
        publicURL
      }     
    } 
    strapiContact {
      title
      cv_text
      cv_url
      img_src {
        publicURL
      }
      roel_cv {
        publicURL
      }
      mail
      mail_text
      newsletter_text
    }
    strapiProspect {
      Prospect {
        date
        img_src {
          publicURL
        }
        location
        title
        url
      }
    }
    allStrapiRetrospect {
      edges {
        node {
          strapiId
          title
          thumbnail {
            publicURL            
          }
          slug
          year
        }
      }
    }
  }
`;

export default IndexPage;
