import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { HiOutlineSortAscending, HiOutlineSortDescending } from "react-icons/hi"

const Retrospects = ({ data }) => {
    const [retrospectList, setRetrospectList] = useState(data);
    const [enabled, setEnabled] = useState(true);

    const sortDesc = () => {
        const sorted = retrospectList.slice().sort((a, b) => {
            return new Date(b.node.year) - new Date(a.node.year);
        });
        setRetrospectList(sorted);
        setEnabled(!enabled)
    };



    const sortAsc = () => {
        const sorted = retrospectList.slice().sort((a, b) => {
            return new Date(a.node.year) - new Date(b.node.year);
        });
        setRetrospectList(sorted);
        setEnabled(!enabled);
    };

    useEffect(() => {
        sortDesc()
    }, []);

    return (
        <div className="container">
            <div className="site-section mt-4" id="retrospect">
                <div className="d-flex justify-content-center">
                    <h2 className="heading-39291" style={{ color: "#0070c0" }}>Retrospect</h2>
                </div>
                <div className="row pb-4">
                    <div className="col-md-12">
                        <button disabled={enabled} className="float-right" style={{ border: "none", background: "none" }}>
                            <HiOutlineSortAscending size="1.5em" style={{ color: enabled == true ? "grey" : "#0070c0", cursor: "pointer" }} onClick={sortAsc} title="Less recent" />
                        </button>
                        <button disabled={!enabled} className="float-right" style={{ border: "none", background: "none" }}>
                            <HiOutlineSortDescending size="1.5em" style={{ color: enabled == false ? "grey" : "#0070c0", cursor: "pointer" }} onClick={sortDesc} title="Most recent" />
                        </button>
                    </div>
                </div>
                <div className="row text-center text-lg-left">
                    {retrospectList.map((item, index) =>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={item.node.strapiId}>
                            <div className="d-block mb-4 h-100 retrospect">
                                <img className="retrospectImage" src={item.node.thumbnail.publicURL} alt={item.node.title} />
                                <Link to={`retrospect/${item.node.slug}`}>
                                    <div className="overlayText">
                                        <h5><span style={{ color: "white" }}>{item.node.title}</span></h5>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};
export default Retrospects;